<template>
	<div>
		<div id="main">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive" />
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive" />
		</div>
	</div>
</template>
<script>
	import {
		getQueryString,
		funcUrlDel
	} from "@/util/util.js"
	import website from "@/config/website.js"
	import {
		Base64
	} from 'js-base64';

	import {
		refreshOauthToken
	} from "@/api/user.js"
	export default {
		name: 'App',
		data() {
			return {
				active: "/home",
				refreshTokenFuc: "",

			};
		},
		watch: {
			"$store.getters.userInfo": {
				immediate: true,
				deep: true,
				handler: function(userInfo) {
					if (this.$store.getters.isLogin) {
						if (userInfo && userInfo.isRealNameValidated == false) {
							this.$router.replace("/realNameValidated")
						}
					}
				}
			},
			"$store.getters.tabbarName": function(newVal, oldVal) {
				this.active = newVal
			}
		},
		methods: {},
		computed: {
			expiresIn() {
				let expires_in;
				if (this.$store.getters.userInfo && this.$store.getters.userInfo.expires_in) {
					expires_in = this.$store.getters.userInfo.expires_in
				}
				if (Number.parseInt(expires_in) < 2000) {
					expires_in = 3600
				}
				return expires_in;
			}
		},
		created() {

		}
	}
</script>
<style lang="less">
	#main {
		height: 100vh;
		width: 100vw;
		background-color: #f7f8fa;
		overflow: hidden;

	}

	#footer {
		height: 50px;
	}

	.safeBottom {
		height: env(safe-area-inset-bottom);
		height: constant(safe-area-inset-bottom);
	}

	.safeTop {
		height: env(safe-area-inset-top);
		height: constant(safe-area-inset-top);
	}
</style>