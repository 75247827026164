/**
 * 全局配置文件
 */
export default {
	title: "西瓜二零服[20全满]38MIR.VIP",
	key: '20F-Wechat',
	clientId: 'wechat',
	clientSecret: 'huchenguang119',
	tokenTime: 3000,
	tokenHeader: 'Blade-Auth',
	// HTTP放行列表
	statusWhiteList: [],
	userType: "gamer",
	userRole: "gamer",
	oauthSource:"WECHAT_MP",
	//使用 nginx 反向代理进行
	
	//oauthUrl: process.env.NODE_ENV === "development"?"http://192.168.200.8:86": "http://api.38mir.vip/",
	switchMode:false,
}
