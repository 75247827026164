import {
	getJsSdkConfig
} from "@/api/wechat.js"
const views = {
	state: {
		tabbarName: "",
		wechatConfig: {},
	},
	mutations: {
		set_tabbarName: (state, tabbarName) => {
			state.tabbarName = tabbarName;
		},
		set_wechatConfig: (state, config) => {
			state.wechatConfig = config;
		}
	},

	actions: {
		getWechatJsConfig({
			commit
		}, url) {
			return new Promise((resolve) => {
				getJsSdkConfig(url).then(res => {
					commit('set_wechatConfig', res.data.data);
					resolve();
				})
			})
		},
	}
}
export default views;
