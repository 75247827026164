import {
	setToken,
	setRefreshToken,
	removeToken,
	removeRefreshToken
} from '@/util/auth.js'
import {
	Toast
} from 'vant';
import router from '@/router/router.js';
import {
	setStore,
	getStore
} from '@/util/store.js'
import {
	isURL,
	validatenull
} from '@/util/validate.js'
import {
	deepClone
} from '@/util/util.js'
import website from '@/config/website.js'
import {
	userInfo
} from '@/api/user.js'
import md5 from 'js-md5'

const user = {
	state: {
		// userInfo: {},
		// roles: [],
		// token: '',
		// refreshToken: '',
		
		
		tenantId: getStore({
			name: 'tenantId'
		}) || '000000',
		userInfo: getStore({
			name: 'userInfo'
		}) || {},
		roles: [],
		token: getStore({
			name: 'token'
		}) || '',
		refreshToken: getStore({
			name: 'refreshToken'
		}) || '',
		sourceUrl: getStore({
			name: 'sourceUrl'
		}) || ''
	},
	actions: {
		//根据第三方信息登录
		GoLogin({
			commit
		}, state) {
			return new Promise((resolve) => {
				setStore({
					name: "sourceUrl",
					content: window.location.href
				})
				router.replace({
					path: "/login"
				});
			})
		},
		UserInfo({
			commit,
			state
		}, stateCode) {
			return new Promise((resolve, reject) => {
				userInfo(stateCode).then(res => {
					const data = res.data;
					if (data && data.access_token) {
						commit('SET_TOKEN', data.access_token);
						commit('SET_REFRESH_TOKEN', data.refresh_token);
						commit('SET_TENANT_ID', data.tenant_id);
						commit('SET_USER_INFO', data);
						// if (!(data && data.is_real_name_validated == true)) {
						// 	router.replace({
						// 		path: "/realNameValidated"
						// 	})
						// } else {
						// 	window.location.replace(state.sourceUrl);
						// }
						//router.replace(state.sourceUrl)
						if (data && data.isRealNameValidated && data.isRealNameValidated == true) {
							window.location.replace(state.sourceUrl);
						}
					} else {
						Toast.fail(error_description);
					}
					resolve();
				}).catch(error => {
					reject(error);
				})
			})
		},

		SetRealNameFlag({
			commit,
			state
		}, val) {
			const data = Object.assign({}, state.userInfo, {
				"isRealNameValidated": val
			});
			commit('SET_USER_INFO', data);
		},
		//获取用户信息
		GetUserInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserInfo().then((res) => {
					const data = res.data.data;
					commit('SET_ROLES', data.roles);
					resolve(data);
				}).catch(err => {
					reject(err);
				})
			})
		},
		//刷新token
		refreshToken({
			state,
			commit
		}, userInfo) {
			//window.console.log('handle refresh token');
			return new Promise((resolve, reject) => {
				refreshToken(state.refreshToken, state.tenantId,
					!validatenull(userInfo) ? -1 : state.userInfo.dept_id,
					!validatenull(userInfo) ? -1 : state.userInfo.role_id
				).then(res => {
					const data = res.data;
					commit('SET_TOKEN', data.access_token);
					commit('SET_REFRESH_TOKEN', data.refresh_token);
					resolve();
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 登出
		LogOut({
			commit
		}) {
			return new Promise((resolve, reject) => {
				logout().then(() => {
					commit('SET_REFRESH_TOKEN', '');
					commit('SET_TOKEN', '');
					commit('SET_ROLES', []);
					commit('SET_USER_INFO', {});
					removeToken();
					removeRefreshToken();
					resolve();
				}).catch(error => {
					reject(error)
				})
			})
		},
		//注销session
		FedLogOut({
			commit
		}) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '');
				commit('SET_REFRESH_TOKEN', '');
				commit('SET_ROLES', []);
				commit('SET_USER_INFO', {});
				removeToken();
				removeRefreshToken();
				resolve();
			})
		},
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			setToken(token);
			state.token = token;
			setStore({
				name: 'token',
				content: state.token
			})
		},
		SET_REFRESH_TOKEN: (state, refreshToken) => {
			setRefreshToken(refreshToken)
			state.refreshToken = refreshToken;
			setStore({
				name: 'refreshToken',
				content: state.refreshToken
			})
		},
		SET_TENANT_ID: (state, tenantId) => {
			state.tenantId = tenantId;
			setStore({
				name: 'tenantId',
				content: state.tenantId
			})
		},
		SET_USER_INFO: (state, userInfo) => {
			if (validatenull(userInfo.avatar)) {
				userInfo.avatar = "/img/bg/img-logo.png";
			}
			state.userInfo = userInfo;
			setStore({
				name: 'userInfo',
				content: state.userInfo
			})
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles;
		},
		SET_PERMISSION: (state, permission) => {
			let result = [];

			function getCode(list) {
				list.forEach(ele => {
					if (typeof(ele) === 'object') {
						const chiildren = ele.children;
						const code = ele.code;
						if (chiildren) {
							getCode(chiildren)
						} else {
							result.push(code);
						}
					}
				})
			}

			getCode(permission);
			state.permission = {};
			result.forEach(ele => {
				state.permission[ele] = true;
			});
			setStore({
				name: 'permission',
				content: state.permission
			})
		}
	}

}



function addPath(ele, first) {
	const menu = website.menu;
	const propsConfig = menu.props;
	const propsDefault = {
		label: propsConfig.label || 'name',
		path: propsConfig.path || 'path',
		icon: propsConfig.icon || 'icon',
		children: propsConfig.children || 'children'
	}
	const icon = ele[propsDefault.icon];
	ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
	const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
	if (!isChild) ele[propsDefault.children] = [];
	if (!isChild && first && !isURL(ele[propsDefault.path])) {
		ele[propsDefault.path] = ele[propsDefault.path] + '/index'
	} else {
		ele[propsDefault.children].forEach(child => {
			addPath(child);
		})
	}

}

export default user