import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router/router'
import store from '@/store'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from '@/router/axios';
import VueAxios from 'vue-axios';

 createApp(App)
.use(VueAxios, axios)
.use(Vant)
.use(store)
.use(router)
.mount('#app')