import {
	createRouter,
	createWebHistory
} from 'vue-router'
import store from '@/store/';
import {
	Toast
} from 'vant';
// import wx from "weixin-sdk-js";
const wx = require('@/util/jweixin.js');
const routes = [{
		path: '/',
		redirect: "/my",
		component: () => import('@/pages/views.vue'),
		children: [{
				path: '/activity',
				name: 'activity',
				meta: {
					title: "西瓜公益 - 活动中心",
					tabbarName: "home",
					isToken: true
				},
				component: () => import('@/views/activity/index.vue')
			},
			{
				path: '/sns',
				name: 'sns',
				meta: {
					title: "西瓜公益 - 二零社区",
					tabbarName: "sns",
					isToken: true
				},
				component: () => import('@/views/sns/index.vue')
			},
			{
				path: '/market',
				name: 'market',
				meta: {
					title: "西瓜公益 - 交易市场",
					tabbarName: "market",
					isToken: true
				},
				component: () => import('@/views/market/index.vue')
			},
			{
				path: '/red',
				name: 'red',
				meta: {
					title: "西瓜公益 - 我的红包",
					tabbarName: "red",
					isToken: true
				},
				component: () => import('@/views/red/index.vue')
			},
			{
				path: '/my',
				name: 'my',
				meta: {
					title: "西瓜公益 - 个人中心",
					tabbarName: "my",
					isToken: true
				},
				component: () => import('@/views/my/index.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: "西瓜公益 - 用户登录",
		},
		component: () => import('@/pages/login.vue')
	},
	{
		path: '/realNameValidated',
		name: 'realNameValidated',
		meta: {
			title: "西瓜公益 - 实名认证",
			isToken: true
		},
		component: () => import('@/pages/realNameValidated.vue')
	},
	{
		path: '/recharge/player',
		name: 'rechargePlayer',
		meta: {
			title: "西瓜公益 - 游戏兑换",
			isToken: true
		},
		component: () => import('@/pages/recharge/player.vue')
	},
	{
		path: '/recharge/player/:gamePlayerUuid',
		name: 'rechargePlayerAll',
		meta: {
			title: "西瓜公益 - 游戏兑换",
			isToken: true
		},
		component: () => import('@/pages/recharge/player.vue')
	},
	{
		path: '/pay/balance',
		name: 'payBalance',
		meta: {
			title: "西瓜公益 - 余额充值",
			isToken: true
		},
		component: () => import('@/pages/pay/balance.vue')
	},
	{
		path: '/pay/balance-zfb',
		name: 'payBalanceZfb',
		meta: {
			title: "西瓜公益 - 支付宝充值",
			isToken: false
		},
		component: () => import('@/pages/pay/balance-zfb.vue')
	},
	{
		path: '/my/boxData',
		name: 'myBoxData',
		meta: {
			title: "西瓜公益 - 盒子转移",
			isToken: true
		},
		component: () => import('@/pages/my/boxData.vue'),
	},
	{
		path: '/my/myPlayers',
		name: 'myPlayers',
		meta: {
			title: "西瓜公益 - 我的角色",
			isToken: true
		},
		component: () => import('@/pages/my/myPlayers.vue'),
	},
	{
		path: '/my/myGoldList',
		name: 'myGoldList',
		meta: {
			title: "西瓜公益 - 我的金币流水",
			isToken: true
		},
		component: () => import('@/pages/my/myGoldList.vue'),
	},
	{
		path: '/my/myBalanceList',
		name: 'myBalanceList',
		meta: {
			title: "西瓜公益 - 我的余额流水",
			isToken: true
		},
		component: () => import('@/pages/my/myBalanceList.vue'),
	},
	{
		path: '/my/myCoinList',
		name: 'myCoinList',
		meta: {
			title: "西瓜公益 - 我的传奇币流水",
			isToken: true
		},
		component: () => import('@/pages/my/myCoinList.vue'),
	},
	{
		path: '/base/bindPlayer/:gamePlayerUuid',
		name: 'bindPlayer',
		meta: {
			title: "西瓜公益 - 绑定角色",
			isToken: true
		},
		component: () => import('@/pages/base/bindPlayer.vue'),
	},
	{
		path: '/base/authLogin',
		name: 'authLogin',
		meta: {
			title: "西瓜公益 - 三方授权",
			isToken: true
		},
		component: () => import('@/pages/base/authLogin.vue'),
	},
	{
		path: '/base/wxApp',
		name: 'wxApp',
		meta: {
			title: "西瓜公益 - 西瓜游戏盒子",
			isToken: false
		},
		component: () => import('@/pages/base/wxApp.vue'),
	},
	{
		path: '/activity/inviteFriends/:gameUuid',
		name: 'inviteFriends',
		meta: {
			title: "西瓜公益 - 呼朋唤友拿红包",
			isToken: true,
			isDiyShare:true
		},
		component: () => import('@/pages/activity/inviteFriends.vue'),
	},
	{
		path: '/base/manualRecharge/:orderId',
		name: 'manualRecharge',
		meta: {
			title: "西瓜公益 - 客服自助充值",
			isToken: false,
		},
		component: () => import('@/pages/base/manualRecharge.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	mode: 'history',
	routes
})




router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// Toast.loading({
	//   forbidClick: true,
	//   duration:0
	// });
	if(to.meta.isDiyShare){
		next();
		return;
	}
	store.dispatch("getWechatJsConfig", window.location.href).then(res => {
		// const shareUrl = document.location.protocol + "//" + window.location.host + to.fullPath
		const shareUrl = document.location.protocol + "//" + window.location.host
		const shareImg = document.location.protocol + "//" + window.location.host + "/img/logo.png"
		const jsApiList = ["scanQRCode", "updateAppMessageShareData", "updateTimelineShareData", "checkJsApi", "chooseImage", "previewImage"]
		const wxConfig = Object.assign({
			// debug:process.env.NODE_ENV === "development",
			jsApiList: jsApiList,
			openTagList: ['wx-open-launch-weapp']
		}, store.state.views.wechatConfig)
		wx.config(wxConfig);
		wx.ready(() => {
			wx.updateAppMessageShareData({
				title: '西瓜公益传奇[WWW.38MIR.VIP]正版授权，20全满！', // 分享标题
				desc: '西瓜公益传奇、正版授权、百余版本、20全满、长期保证。', // 分享描述
				link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
				imgUrl: shareImg, // 分享图标
				success: function() {
					console.log("设置普通分享成功")
				}
			});
			wx.updateTimelineShareData({
				title: '二零服[WWW.38MIR.VIP]正版授权，百余版本20全满！', // 分享标题
				link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
				imgUrl: shareImg, // 分享图标
				success: function() {
					console.log("设置普通分享成功")
				}
			});
		})
		// Toast.clear()
		next()
	})
})
// if(to.meta.tabbarName){
// 	store.commit("set_tabbarName",to.meta.tabbarName)
// }
// })

export default router
