const getters = {
	isLogin: (state) => {
		return state.user.userInfo && state.user.userInfo.account != null && state.user.token != null && state
			.user.refreshToken != null;
	},
	userInfo: state => state.user.userInfo,
	token: state => state.user.token,
	sourceUrl: state => state.user.sourceUrl,

	refreshToken: state => state.user.refreshToken,
	roles: state => state.user.roles,
	permission: state => state.user.permission,

	tabbarName: state => state.views.tabbarName,
	
	wechatConfig: state => state.views.wechatConfig,
}
export default getters