import { createStore } from 'vuex'
import user from './modules/user'
import views from './modules/views'
import getters from './getters'
export default createStore({
  modules: {
    user,
	views
  },
  getters
})
